import {
  Answer,
  CustomizerProduct,
  EntityType,
  Group,
  Part,
  PrintArea,
  PrintAreaMeasurementUnit,
  CustomizableQuestion,
  Rule,
  StartingPoint,
  CustomizerProductSubmitAction,
} from '@packages/types'
import { AnyAction } from 'redux'

import { defaultDimensions } from 'common/customizerProducts/constants'
import { bulkOrderRowCustomizationSelector } from 'customizer/bulkOrder/selectors'

import * as bulkOrderTypes from '../../bulkOrder/actionTypes'
import * as types from '../actionTypes'
import applyLinkedQuestions from './applyLinkedQuestions'
import createColorAnswer from './createColorAnswer'
import createFontSizeAnswer from './createFontSizeAnswer'
import createLogoAnswer from './createLogoAnswer'
import createTextAnswer from './createTextAnswer'
import removeAnswer from './removeAnswer'
import selectAnswer from './selectAnswer'
import selectAnswers from './selectAnswers'
import selectAnswerWhereAvailable from './selectAnswerWhereAvailable'
import setQuestionDefaultConfiguration from './setQuestionDefaultConfiguration'
import setQuestionHasFilteredWord from './setQuestionHasFilteredWord'
import setQuestionHasRequiredError from './setQuestionHasRequiredError'
import startCustomization from './startCustomization'
import startDesignCustomization from './startDesignCustomization'
import updateAnswerText from './updateAnswerText'
import updateColorAnswer from './updateColorAnswer'
import updateFontSizeAnswer from './updateFontSizeAnswer'
import updatePrintAreaLogoPositionAnswer from './updatePrintArealogoPositionAnswer'
import updatePrintAreaTextPositionAnswer from './updatePrintAreaTextPositionAnswer'

export type CustomizationState = {
  isReady: boolean
  isCustomizingDesign: boolean
  isSubmitEnabled: boolean
  productName: string | null
  productId: string | null
  questions: Record<string, CustomizableQuestion>
  answers: Record<string, Answer>
  rules: Record<string, Rule>
  parts: Record<string, Part>
  groups: Record<string, Group>
  printAreas: Record<string, PrintArea & { editable?: boolean }>
  customizerProduct: CustomizerProduct
  startingPoint?: StartingPoint
}

const initialState: CustomizationState = {
  isReady: false,
  isCustomizingDesign: false,
  isSubmitEnabled: true,
  productName: null,
  productId: null,
  questions: {},
  answers: {},
  rules: {},
  parts: {},
  groups: {},
  customizerProduct: {
    id: '',
    jsVersion: '3.0.0',
    tree: {
      id: '',
      name: '',
      entityType: EntityType.Group,
      children: [],
    },
    rules: [],
    parts: [],
    questions: [],
    assets: [],
    defaultConfiguration: {},
    themesConfiguration: {},
    dimensions: defaultDimensions,
    printAreas: [],
    views: 1,
    submitAction: CustomizerProductSubmitAction.AddToCart,
  },
  printAreas: {
    'print-area-1': {
      id: 'print-area-1',
      entityType: EntityType.PrintArea,
      name: 'Front print',
      dpi: 300,
      fileExtension: 'pdf',
      measurementUnit: PrintAreaMeasurementUnit.Inches,
      width: 6,
      height: 8,
      bleed: 0.5,
      margins: {
        horizontal: 0,
        vertical: 0,
      },
      productPreview: {
        designView: 0,
        outlineColor: '#000000',
        isShown: true,
        views: [
          {
            x: 100,
            y: 200,
            scale: 0.2,
            hidden: false,
            rotation: 0,
          },
        ],
        masks: ['PART-1'],
      },
    },
  },
}

interface ActionWithPayload extends AnyAction {
  payload: any
}

export default (state: CustomizationState = initialState, action: AnyAction): CustomizationState => {
  switch (action.type) {
    case types.START_CUSTOMIZATION:
      return startCustomization(state, action)
    case types.START_DESIGN_CUSTOMIZATION:
      return startDesignCustomization(state, action)
    case types.SELECT_ANSWER_WHERE_AVAILABLE:
      return selectAnswerWhereAvailable(state, action)
    case types.SELECT_ANSWER:
      return selectAnswer(state, action as ActionWithPayload)
    case types.SELECT_ANSWERS:
      return selectAnswers(state, action as ActionWithPayload)
    case types.UPDATE_TEXT_ANSWER:
      return updateAnswerText(state, action)
    case types.CREATE_LOGO_ANSWER:
      return createLogoAnswer(state, action)
    case types.CREATE_TEXT_ANSWER:
      return createTextAnswer(state, action)
    case types.CREATE_COLOR_ANSWER:
      return createColorAnswer(state, action)
    case types.UPDATE_COLOR_ANSWER:
      return updateColorAnswer(state, action)
    case types.CREATE_FONT_SIZE_ANSWER:
      return createFontSizeAnswer(state, action)
    case types.UPDATE_FONT_SIZE_ANSWER:
      return updateFontSizeAnswer(state, action)
    case types.UPDATE_PRINT_AREA_LOGO_POSITION_ANSWER:
      return updatePrintAreaLogoPositionAnswer(state, action)
    case types.UPDATE_PRINT_AREA_TEXT_POSITION_ANSWER:
      return updatePrintAreaTextPositionAnswer(state, action)
    case types.REMOVE_ANSWER:
      return removeAnswer(state, action as ActionWithPayload)
    case types.SET_QUESTION_DEFAULT_CONFIGURATION:
      return setQuestionDefaultConfiguration(state, action as ActionWithPayload)
    case types.SET_QUESTION_HAS_FILTERED_WORD:
      return setQuestionHasFilteredWord(state, action as ActionWithPayload)
    case types.SET_QUESTION_HAS_REQUIRED_ERROR:
      return setQuestionHasRequiredError(state, action as ActionWithPayload)
    case types.DISABLE_SUBMIT:
      return { ...state, isSubmitEnabled: false }
    case bulkOrderTypes.UPDATE_SHOWN_ROW_INDEX:
    case bulkOrderTypes.UPDATE_PREVIEWED_ROW_INDEX: {
      const newState = applyLinkedQuestions(bulkOrderRowCustomizationSelector(state, action.payload.row))
      return { ...state, ...newState }
    }
    default:
      return state
  }
}
