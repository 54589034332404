import { OverflowBehavior } from '@packages/types'

import isElementOverflowing from 'utils/isElementOverflowing'

import TextSVG, { TextSVGParams } from './TextSVG'

export interface MultilineTextSVGParams extends TextSVGParams {
  position: {
    textAlign: string
    verticalAlign: string
    boundingBox?: { maxWidth?: number; maxHeight?: number }
    overflowBehavior?: OverflowBehavior
  }
  padding?: number
}

export default class MultilineTextSVG extends TextSVG {
  private textContainerElement
  private foreignObjectElement
  private textElement

  public adjustedFontSize = 30

  constructor() {
    super()

    this.textElement = document.createElementNS('http://www.w3.org/1999/xhtml', 'div')
    this.textElement.style.display = 'flex'
    this.textElement.style.overflowWrap = 'anywhere'
    this.textElement.style.zIndex = '0'
    this.textElement.style.width = '100%'
    this.textElement.style.height = '100%'
    this.textElement.style.whiteSpace = 'pre-wrap'
    this.textElement.style.lineHeight = '1'

    this.textContainerElement = document.createElementNS('http://www.w3.org/1999/xhtml', 'div')
    this.textContainerElement.appendChild(this.textElement)

    this.foreignObjectElement = document.createElementNS(TextSVG.ns, 'foreignObject')
    this.foreignObjectElement.setAttributeNS(null, 'width', '100%')
    this.foreignObjectElement.setAttributeNS(null, 'height', '100%')
    this.foreignObjectElement.style.overflow = 'visible'
    this.foreignObjectElement.appendChild(this.textContainerElement)

    this.svgElement.style.overflow = 'visible'
    this.svgElement.appendChild(this.foreignObjectElement)
  }

  public async build(params: MultilineTextSVGParams) {
    await this.setFont(params)
    this.setText(params)
    this.setDimensions(params)
    this.setPosition(params)
    this.setOutline(params)
    this.applySVGFilters(params)
  }

  private setDimensions(params: MultilineTextSVGParams) {
    const boundingBox = params.position.boundingBox
    const axisPadding = (params.padding || 0) * 2

    if (boundingBox?.maxWidth) {
      this.svgElement.setAttributeNS(null, 'width', String(boundingBox.maxWidth + axisPadding))
      this.textContainerElement.style.width = `${boundingBox.maxWidth}px`
    } else {
      this.textContainerElement.style.width = 'max-content'
    }

    if (boundingBox?.maxHeight && params.position.overflowBehavior !== 'visible') {
      this.svgElement.setAttributeNS(null, 'height', String(boundingBox.maxHeight + axisPadding))
      this.textContainerElement.style.height = `${boundingBox.maxHeight}px`
    } else {
      this.textContainerElement.style.height = 'auto'
    }

    if (!boundingBox?.maxWidth || !boundingBox?.maxHeight) {
      this.textContainerElement.style.overflow = 'visible'
      this.beginWork()
      const { width, height } = this.textElement.getBoundingClientRect()
      if (!boundingBox?.maxWidth) this.svgElement.setAttributeNS(null, 'width', String(width + axisPadding))
      if (!boundingBox?.maxHeight) this.svgElement.setAttributeNS(null, 'height', String(height + axisPadding))
      this.finishWork()
    } else if (params.position.overflowBehavior === OverflowBehavior.Resize) {
      this.textContainerElement.style.overflow = 'visible'
      this.setFontSizeToFitInBoundingBox(params)
    } else if (params.position.overflowBehavior === OverflowBehavior.Clip) {
      this.textContainerElement.style.overflow = 'hidden'
    } else if (params.position.overflowBehavior === OverflowBehavior.Visible) {
      this.textContainerElement.style.overflow = 'visible'
      this.beginWork()
      const { height } = this.textElement.getBoundingClientRect()
      this.svgElement.setAttributeNS(null, 'height', String(height + axisPadding))
      this.finishWork()
    }

    if (params.padding) {
      this.foreignObjectElement.setAttributeNS(null, 'x', String(params.padding))
      this.foreignObjectElement.setAttributeNS(null, 'y', String(params.padding))
    } else {
      this.foreignObjectElement.setAttributeNS(null, 'x', String(0))
      this.foreignObjectElement.setAttributeNS(null, 'y', String(0))
    }
  }

  private setPosition(params: MultilineTextSVGParams) {
    switch (params.position.textAlign) {
      case 'left':
        this.textElement.style.justifyContent = 'flex-start'
        this.textElement.style.textAlign = 'left'
        break
      case 'right':
        this.textElement.style.justifyContent = 'flex-end'
        this.textElement.style.textAlign = 'right'
        break
      case 'center':
      default:
        this.textElement.style.justifyContent = 'center'
        this.textElement.style.textAlign = 'center'
        break
    }

    switch (params.position.verticalAlign) {
      case 'top':
        this.textElement.style.alignItems = 'flex-start'
        break
      case 'bottom':
        this.textElement.style.alignItems = 'flex-end'
        break
      case 'middle':
      default:
        this.textElement.style.alignItems = 'center'
        break
    }
  }

  private setText(params: MultilineTextSVGParams) {
    this.textElement.innerText = params.text.value || this.defaultValues.text
    this.textElement.style.color = params.color?.hex || this.defaultValues.color
    this.textElement.style.fontSize = params.font.size || this.defaultValues.fontSize
    this.textElement.style.fontFamily = params.font.family || this.defaultValues.fontFamily
  }

  private setOutline(params: MultilineTextSVGParams) {
    this.svgElement.querySelector('#text-outline')?.remove()

    if (this.hasOutline(params)) {
      const outlineElement = this.foreignObjectElement.cloneNode(true) as SVGForeignObjectElement
      outlineElement.setAttributeNS(null, 'id', 'text-outline')
      outlineElement.setAttributeNS(null, 'filter', '')
      const textElement = outlineElement.children[0].children[0] as HTMLElement
      textElement.style.webkitTextStroke = `${params.outline!.width!}px ${params.outline!.hex!}`
      this.svgElement.insertBefore(outlineElement, this.foreignObjectElement)
    }
  }

  private applySVGFilters(node: MultilineTextSVGParams) {
    const filter = super.createSVGFilters(node)
    if (filter) {
      this.foreignObjectElement.setAttributeNS(null, 'filter', `url(#${filter.id})`)
    }
  }

  private setFontSizeToFitInBoundingBox(params: MultilineTextSVGParams) {
    this.beginWork()

    const textContainerElement = this.textContainerElement.cloneNode() as HTMLElement
    const textElement = this.textElement.cloneNode(true) as HTMLElement
    textContainerElement.appendChild(textElement)
    this.foreignObjectElement.appendChild(textContainerElement)
    textElement.style.alignItems = 'flex-start'
    textElement.style.textAlign = 'left'

    let adjustedFontSize = parseFloat(params.font.size)
    textElement.style.fontSize = `${adjustedFontSize}px`

    while (isElementOverflowing(textContainerElement) && adjustedFontSize > 0) {
      textElement.style.fontSize = `${adjustedFontSize}px`
      adjustedFontSize = adjustedFontSize - 0.1
    }

    this.textElement.style.fontSize = `${adjustedFontSize}px`

    this.foreignObjectElement.removeChild(textContainerElement)

    this.finishWork()

    this.adjustedFontSize = adjustedFontSize
  }
}
